import { Dialog, Transition } from "@headlessui/react";
import React, { Fragment } from "react";
import { Reason } from "../../model/Reason";
import { SupportCaseState, SupportCaseStateAction } from "../state/SupportCaseState";
import { CurrentListing } from "./CurrentListing";
import { MobileMenuButton } from "./MobileMenuButton";

interface SlideOverProps {
  supportCase: SupportCaseState;
  dispatchSupportCaseAction: (supportCaseAction: SupportCaseStateAction) => void;
  reasonsWithSolutions: Reason[];
  isOpen: boolean;
  handleOnClose(bool: boolean): void;
  handleCloseClick(e: React.MouseEvent<HTMLDivElement, MouseEvent>): void;
}

export const SlideOver: React.FC<SlideOverProps> = ({
  supportCase,
  dispatchSupportCaseAction,
  reasonsWithSolutions,
  isOpen,
  handleOnClose,
  handleCloseClick,
}) => {
  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        static={false}
        className="fixed inset-0 z-30 overflow-hidden"
        open={isOpen}
        onClose={(bool) => handleOnClose(bool)}
      >
        <div className="absolute inset-0 overflow-hidden">
          <Dialog.Overlay className="absolute inset-0 bg-black bg-opacity-50" />

          <div className="fixed inset-y-0 right-0 flex max-w-full pl-10">
            <Transition.Child
              as={Fragment}
              enter="transform transition ease-in-out duration-500 sm:duration-700"
              enterFrom="translate-x-full"
              enterTo="translate-x-0"
              leave="transform transition ease-in-out duration-500 sm:duration-700"
              leaveFrom="translate-x-0"
              leaveTo="translate-x-full"
            >
              <div className="w-screen max-w-md">
                <div className="flex flex-col h-full py-6 overflow-y-scroll bg-white shadow-xl">
                  <div className="px-4 sm:px-6">
                    <div className="flex items-start justify-between">
                      <Dialog.Title className="text-lg font-medium text-gray-900"></Dialog.Title>
                      <div className="flex items-center ml-3 h-7">
                        <MobileMenuButton isOpen={isOpen} handleClick={(e) => handleCloseClick(e)} />
                      </div>
                    </div>
                  </div>

                  <div className="relative mt-6">
                    <CurrentListing
                      supportCase={supportCase}
                      dispatchSupportCaseAction={dispatchSupportCaseAction}
                      reasonsWithSolutions={reasonsWithSolutions}
                    />
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};
