import { decode } from "jsonwebtoken";
import { NextPage } from "next";
import { useEffect, useReducer, useState } from "react";
import { Head } from "../frontend/components/Head";
import Layout from "../frontend/components/Layout";
import { Loader } from "../frontend/components/Loader";
import { Logout } from "../frontend/components/Logout";
import { SectionHeadline } from "../frontend/components/SectionHeadline";
import { Summary } from "../frontend/components/Summary";
import { SupportCaseEditorLayout } from "../frontend/components/SupportCaseEditorLayout";
import { localStore } from "../frontend/localStore";
import { supportCaseStateReducer } from "../frontend/state/SupportCaseState";
import { Order } from "../model/Order";
import { Reason } from "../model/Reason";
import { SupportCaseDb, SupportCaseStatus } from "../model/SupportCase";
import { ApiClient } from "../utils/ApiClient";
import { SupportCaseMapper } from "../utils/SupportCaseMapper";

const Index: NextPage = () => {
  const [isOpenMobileMenu, setIsOpenMobileMenu] = useState(false);

  const [supportCase, dispatchSupportCaseAction] = useReducer(supportCaseStateReducer, {
    ordernumber: "unknown",
    orderState: "unknown",
    invoiceDate: "unknown",
    country: "unknown",
    status: SupportCaseStatus.Editing,
    agreedTerms: "unknown",
    items: [],
  });

  // const authState = useAuthState();
  // if (authState.authStatus !== "authenticated") throw new Error("should not throw, you are on an authenticated page");
  // const {
  //   authToken: token,
  //   user: { ordernumber },
  // } = authState;

  // ? Why not get from context
  const [token, setToken] = useState("");
  const [ordernumber, setOrdernumber] = useState("");
  const [superSupportCase, setSuperSupportCase] = useState(supportCase);
  const [error, setError] = useState("");

  useEffect(() => {
    const authToken = localStore.getAuthToken();
    if (authToken) {
      setToken(authToken);
      const tokenDecoded = decode(authToken) as { payload: { ordernumber: string } };
      setOrdernumber(tokenDecoded?.payload?.ordernumber);
    }
  }, []);

  // Get reasons once globally here and pass
  const [reasonsWithSolutions, setReasonsWithSolutions] = useState<Reason[]>([]);
  useEffect(() => {
    (async () => {
      if (!token) return;
      const reasonsData = await ApiClient.withXAuthToken(token)
        .get<Reason[]>("/api/reasons/")
        .catch((e) => console.error(e));
      if (reasonsData) setReasonsWithSolutions(reasonsData);
    })();
  }, [token]);

  // Push State to API whenever state changes
  useEffect(() => {
    (async () => {
      // do not send to api anymore when one of the following states is reached
      setSuperSupportCase(supportCase);
      if (
        supportCase.status === SupportCaseStatus.Validating ||
        supportCase.status === SupportCaseStatus.Canceled ||
        supportCase.status === SupportCaseStatus.Shipping
      )
        return;
      if (supportCase.ordernumber !== "unknown") {
        const transmitObject = SupportCaseMapper.stateToApi(supportCase);

        const response = await ApiClient.withXAuthToken(token)
          .post<SupportCaseDb>(`/api/support-cases`, transmitObject)
          .catch((e) => {
            // setError(
            //   "Sorry! Etwas ist schief gelaufen. Die Serviceanfrage konnte nicht übermittelt werden. Gerne kannst du es später nochmals probieren oder du wendest dich direkt an kundenservice@ehrenkind.de ",
            // );
            console.error(e);
          });

        const newSupportCase = {
          ...supportCase,
        };
        newSupportCase.items.forEach((item) => {
          const match = response?.returnItems.find((returnItem) => returnItem.sku === item.product.sku);
          item.returnFee = match?.returnFee;
        });
        console.log(newSupportCase);
      }
    })();
  }, [supportCase, token]);

  // On start, get order / support case
  useEffect(() => {
    (async () => {
      try {
        if (!ordernumber) return;

        const order = await ApiClient.withXAuthToken(token).get<Order>("/api/orders/", ordernumber);
        const { orderItems, invoiceDate, country, state: orderState } = order;
        let existingSupportCase;

        try {
          existingSupportCase = await ApiClient.withXAuthToken(token).get<SupportCaseDb>(
            `/api/support-cases/${ordernumber}`,
          );
          if (existingSupportCase) {
            // continue case
            const oldState = SupportCaseMapper.apiToState(existingSupportCase, orderItems || [], orderState);
            dispatchSupportCaseAction({
              type: "continue",
              supportCase: oldState,
            });
          }
        } catch (error) {
          // log.info("No support case found with curent order number");
        }

        if (!existingSupportCase) {
          dispatchSupportCaseAction({
            type: "reset",
            country,
            ordernumber,
            orderState,
            invoiceDate: invoiceDate ? invoiceDate.toString().split("T")[0] : undefined,
            orderItems: orderItems ?? [],
            customerMessage: undefined,
            status: SupportCaseStatus.Editing,
            agreedTerms: "",
          });
        }
      } catch (error) {
        console.error("Could not get order");
        setError(
          "Sorry, die Bestellung konnte nicht geladen werden. Bitte wende dich direkt an kundenservice@ehrenkind.de",
        );
      }
    })();
  }, [token, ordernumber]);

  const isLoading = !(superSupportCase.ordernumber !== "unknown" && reasonsWithSolutions);

  // ! disabled warranty check 2 years, let cc do it manually
  // if (
  //   supportCase.invoiceDate &&
  //   supportCase.invoiceDate !== "unknown" &&
  //   LocalDate.parse(supportCase.invoiceDate).plusYears(2).isBefore(LocalDate.now())
  // ) {
  //   return <Expired />;
  // }

  if (
    superSupportCase.status === SupportCaseStatus.Canceled ||
    superSupportCase.status === SupportCaseStatus.Validating ||
    superSupportCase.status === SupportCaseStatus.Shipping
  ) {
    return <Summary supportCase={superSupportCase} reasonsWithSolutions={reasonsWithSolutions} />;
  }

  return (
    <>
      <Head />
      <Layout isLoading={isLoading}>
        {error ? (
          <>
            <SectionHeadline title="Ein Problem ist aufgetreten" />
            <div className="mb-9">{error}</div>
            <div className="float-right">
              <Logout>Logout</Logout>
            </div>
          </>
        ) : isLoading ? (
          <Loader isFullHeight={false} className="mt-20" />
        ) : (
          <>
            <SupportCaseEditorLayout
              dispatchSupportCaseAction={dispatchSupportCaseAction}
              isOpenMobileMenu={isOpenMobileMenu}
              reasonsWithSolutions={reasonsWithSolutions}
              setIsOpenMobileMenu={setIsOpenMobileMenu}
              supportCase={superSupportCase}
            />
          </>
        )}
      </Layout>
    </>
  );
};

export default Index;
