/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { useState } from "react";
import { Reason } from "../../../model/Reason";
import { isReturnDateOk, jsDateToLocalDate } from "../../../utils/helper";
import { SupportCaseState, SupportCaseStateAction } from "../../state/SupportCaseState";
import { EditorFooterActions } from "../EditorFooterActions";
import { Loader } from "../Loader";
import { ReasonExpired } from "../ReasonExpired";
import { BatchNumberEditor } from "./BatchNumberEditor";
import { ComplaintEditor } from "./ComplaintEditor";
import { OrderItemsEditor } from "./OrderItemsEditor";
import { ReasonEditor } from "./ReasonEditor";
import { ReturnQuantityEditor } from "./ReturnQuantityEditor";
import { SolutionEditor } from "./SolutionEditor";

interface SupportCaseEditorProps {
  supportCase: SupportCaseState;
  dispatchSupportCaseAction: (supportCaseAction: SupportCaseStateAction) => void;
  reasonsWithSolutions: Reason[];
}

function showOrderItemsEditor(): boolean {
  return true;
}

function showReturnQuantityEditor(sc: SupportCaseState): boolean {
  if (!showOrderItemsEditor()) return false;
  if (!sc.selectedItem) return false;
  return true;
}

function showReasonEditor(sc: SupportCaseState): boolean {
  if (!showReturnQuantityEditor(sc)) return false;
  if (sc.selectedItem!.returnQuantity > 0) return true;
  return false;
}

function showReasonExpired(sc: SupportCaseState): boolean {
  if (!showReasonEditor(sc)) return false;
  if (
    sc.selectedItem?.reason &&
    !sc.selectedItem?.isComplaint &&
    sc.invoiceDate &&
    !isReturnDateOk(jsDateToLocalDate(new Date(sc.invoiceDate)))
  )
    return true;
  return false;
}

function showBatchNumberEditor(sc: SupportCaseState): boolean {
  if (!showReasonEditor(sc)) return false;
  if (showReasonExpired(sc)) return false;
  if (sc.selectedItem!.reason && sc.selectedItem!.isComplaint && sc.selectedItem!.product.batchNumberNeeded)
    return true;
  else return false;
}

function showComplaintEditor(sc: SupportCaseState): boolean {
  if (!showReasonEditor(sc)) return false;
  if (showReasonExpired(sc)) return false;
  if (showBatchNumberEditor(sc) && sc.selectedItem?.batchNumber) return true;
  if (sc.selectedItem!.reason && sc.selectedItem!.isComplaint && !sc.selectedItem!.product.batchNumberNeeded)
    return true;
  else return false;
}

function showSolutionEditor(sc: SupportCaseState): boolean {
  if (!showReasonEditor(sc)) return false;
  if (showReasonExpired(sc)) return false;
  if (!sc.selectedItem?.reason) return false;
  if (!sc.selectedItem?.isComplaint) return true;
  if (showComplaintEditor(sc) && sc.selectedItem.complaintDescription && sc.selectedItem.reason !== "damagedByCarrier")
    return true;
  if (
    showComplaintEditor(sc) &&
    sc.selectedItem.reason === "damagedByCarrier" &&
    sc.selectedItem.complaintImages.length > 1
  )
    return true;
  else return false;
}

export type ComplaintImagesLoadingState = "LOADED" | "UPLOADING" | "UPLOADED" | "CANCELED";

export const SupportCaseEditor: React.FC<SupportCaseEditorProps> = ({
  supportCase,
  dispatchSupportCaseAction,
  reasonsWithSolutions,
}): JSX.Element => {
  const [isUploadingInProgress, setIsUploadingInProgress] = useState<ComplaintImagesLoadingState>("LOADED");

  return (
    <>
      <div className="pb-32">
        <div className="space-y-16">
          {showOrderItemsEditor() && (
            <OrderItemsEditor supportCase={supportCase} dispatchSupportCaseAction={dispatchSupportCaseAction} />
          )}
          {showReturnQuantityEditor(supportCase) && (
            <ReturnQuantityEditor
              supportCaseItem={supportCase.selectedItem!}
              dispatchSupportCaseAction={dispatchSupportCaseAction}
            />
          )}
          {showReasonEditor(supportCase) && (
            <ReasonEditor
              supportCaseItem={supportCase.selectedItem!}
              dispatchSupportCaseAction={dispatchSupportCaseAction}
              reasonsWithSolutions={reasonsWithSolutions}
            />
          )}
          {showReasonExpired(supportCase) && <ReasonExpired />}
          {showBatchNumberEditor(supportCase) && (
            <BatchNumberEditor
              supportCaseItem={supportCase.selectedItem!}
              dispatchSupportCaseAction={dispatchSupportCaseAction}
            />
          )}
          {showComplaintEditor(supportCase) && (
            <ComplaintEditor
              supportCaseItem={supportCase.selectedItem!}
              dispatchSupportCaseAction={dispatchSupportCaseAction}
              handleIsUploadingInProgress={(status) => {
                setIsUploadingInProgress(status);
              }}
              isUploading={isUploadingInProgress === "UPLOADING"}
              handleAreAllImagesFullyLoaded={() => {
                const isAllImagesLoaded = supportCase.selectedItem?.complaintImages.every((image) => image.isLoaded);
                if (isAllImagesLoaded && isUploadingInProgress === "UPLOADED") setIsUploadingInProgress("LOADED");
              }}
            />
          )}
          {showSolutionEditor(supportCase) && (
            <SolutionEditor
              supportCaseItem={supportCase.selectedItem!}
              dispatchSupportCaseAction={dispatchSupportCaseAction}
              reasonsWithSolutions={reasonsWithSolutions}
            />
          )}
          {showOrderItemsEditor() && (
            <div className="!mt-20">
              <EditorFooterActions
                supportCase={supportCase}
                dispatchSupportCaseAction={dispatchSupportCaseAction}
                reasonsWithSolutions={reasonsWithSolutions}
              />
            </div>
          )}
        </div>
      </div>
      {isUploadingInProgress !== "CANCELED" && isUploadingInProgress !== "LOADED" && <Loader isOverlay />}
    </>
  );
};
