import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import { IS_APP_ENV_PRODUCTION } from "../../utils/constantsPublic";
import { useAuthDispatch } from "../contexts/AuthContext";
import { localStore } from "../localStore";
import { Loader } from "./Loader";

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface AutoLogoutForwardProps {}

export const AutoLogoutForward: React.FC<AutoLogoutForwardProps> = () => {
  const [timer, setTimer] = useState(IS_APP_ENV_PRODUCTION ? 120 : 99999);
  const router = useRouter();
  const dispatchAuthState = useAuthDispatch();

  useEffect(() => {
    if (timer > 0) {
      const interval = setInterval(() => {
        setTimer((c) => c - 1);
      }, 1000);
      return () => clearInterval(interval);
    } else {
      localStore.deleteAuthToken();
      // dispatchAuthState({ type: "logout" });
      router.push("https://www.ehrenkind.de");
    }
  }, [dispatchAuthState, router, timer]);

  return (
    <>
      {timer <= 0 ? <Loader isFullHeight={false} /> : <span>Du wirst automatisch ausgeloggt in {timer} Sekunden.</span>}
    </>
  );
};
