import { RadioGroup } from "@headlessui/react";
import { GenericImage } from "../../model/GenericImage";
import { classNames } from "../../utils/helper";
import { style } from "../styles/styles";
import { Checkmark } from "./Checkmark";
import { ImageWithFallback } from "./ImageWithFallback";

export interface StackedRadioCard {
  value: string;
  label: string;
  image?: GenericImage;
  withImage?: boolean;
  description?: string;
  isCheckedNotActive: boolean;
}

interface StackedRadioCardsProps {
  title: string;
  items: StackedRadioCard[];
  selected: string | undefined;
  setSelected: (id: string) => void;
}

export const StackedRadioCards: React.FC<StackedRadioCardsProps> = ({ title, items, selected, setSelected }) => {
  return (
    <RadioGroup value={selected} onChange={setSelected}>
      <RadioGroup.Label className="sr-only">{title}</RadioGroup.Label>
      <div className="relative -space-y-px rounded-md">
        {items.map((item, itemIdx) => (
          <RadioGroup.Option
            key={item.value}
            value={item.value}
            className={({ checked }) =>
              classNames(
                itemIdx === 0 ? "rounded-tl-md rounded-tr-md" : "",
                itemIdx === items.length - 1 ? "rounded-bl-md rounded-br-md" : "",
                checked ? " border-gray-200" : "border-gray-200 opacity-80",
                item.withImage
                  ? "grid-cols-radioSmallImageTextCard lg:grid-cols-radioImageTextCard"
                  : "grid-cols-radioTextCard",
                `relative border p-4 cursor-pointer md:pl-4 md:pr-6 grid focus:border-white focus:outline-none focus:ring-2 focus:ring-primary-600`,
              )
            }
          >
            {({ checked }) => (
              <>
                {item.withImage && (
                  <RadioGroup.Description as="div" className="flex mx-auto">
                    <ImageWithFallback image={item.image} />
                  </RadioGroup.Description>
                )}

                <RadioGroup.Description as="div" className="flex items-center px-4 py-4">
                  <RadioGroup.Label as="div">
                    <div className={`${style.headline3} mb-1`}>{item.label}</div>
                    {item.description && (
                      <div
                        className={`${style.headlineMeta} `}
                        dangerouslySetInnerHTML={{ __html: item.description }}
                      ></div>
                    )}
                  </RadioGroup.Label>
                </RadioGroup.Description>
                <RadioGroup.Description as="div" className="flex items-center justify-end pl-4">
                  <div className="w-10 lg:w-14">
                    <div aria-hidden="true">
                      <Checkmark isCurrent={checked} isSelected={item.isCheckedNotActive} />
                    </div>
                  </div>
                </RadioGroup.Description>
              </>
            )}
          </RadioGroup.Option>
        ))}
      </div>
    </RadioGroup>
  );
};
