import { useRouter } from "next/router";
import React from "react";
import { useAuthDispatch } from "../contexts/AuthContext";
import { localStore } from "../localStore";
import { Button } from "./Button";

interface LogoutProps {
  title?: string;
}

export const Logout: React.FC<LogoutProps> = ({ children, ...props }) => {
  const dispatchAuthState = useAuthDispatch();
  const router = useRouter();

  function handleClick(): void {
    localStore.deleteAuthToken();
    dispatchAuthState({ type: "logout" });
    router.push("/login");
  }

  return (
    <Button
      isBlock
      variant="secondary"
      // className={`inline-flex items-center ${style.paragraphLink} ${style.paragraphMargin}`}
      onClick={handleClick}
      {...props}
    >
      {children}
    </Button>
  );
};
