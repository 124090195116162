import { Reason } from "../model/Reason";

export const getLocalizedStringFromReasons = (items: Reason[], reasonName: string | undefined): string | undefined =>
  items?.find((item) => item.name === reasonName)?.description?.[0].value;

export const getLocalizedSolutionStringFromReasons = (
  items: Reason[],
  reasonName: string,
  solutionName: string,
): string | undefined =>
  items?.find((item) => item.name === reasonName)?.solutions?.find((item) => item.name === solutionName)?.title?.[0]
    .value;
