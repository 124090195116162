import React from "react";
import { style } from "../styles/styles";

export interface CheckboxProps {
  isRequired: boolean;
  item: { id: string; value: string };
  isChecked: boolean;
  onValueChange: (val: string) => void;
}

export const Checkbox: React.FC<CheckboxProps> = ({ item, isRequired, isChecked, onValueChange }) => {
  return (
    <div role="group" aria-labelledby="label-reason">
      <div className="mt-4 sm:mt-0">
        <div className="space-y-4">
          <div key={item.id} className="relative flex items-start">
            <div className="flex items-center h-5">
              <input
                id={item.id}
                name={item.id}
                type="checkbox"
                checked={isChecked}
                onChange={() => onValueChange(item.value)}
                className="w-4 h-4 text-gray-600 border-gray-300 rounded focus:ring-gray-500"
                required={isRequired}
              />
            </div>
            <div className="ml-3">
              <label htmlFor={item.id} className={`${style.paragraph}`}>
                {item.value}
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
