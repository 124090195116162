import { LightBulbIcon, QuestionMarkCircleIcon, TrashIcon } from "@heroicons/react/solid";
import { DateTimeFormatter, LocalDate } from "@js-joda/core";
import { AnimatePresence, motion } from "framer-motion";
import React from "react";
import { Reason } from "../../model/Reason";
import { getLocalizedSolutionStringFromReasons, getLocalizedStringFromReasons } from "../../utils/getLocalizedString";
import { SupportCaseState, SupportCaseStateAction } from "../state/SupportCaseState";
import { style } from "../styles/styles";

interface CurrentListingProps {
  supportCase: SupportCaseState;
  dispatchSupportCaseAction: (supportCaseAction: SupportCaseStateAction) => void;
  reasonsWithSolutions: Reason[];
}

const borderWidth = "border-l-8";

export const CurrentListing: React.FC<CurrentListingProps> = ({
  supportCase,
  reasonsWithSolutions,
  dispatchSupportCaseAction,
}) => {
  function handleDeleteClick(e: React.MouseEvent<HTMLButtonElement>, sku: string): void {
    e.preventDefault();
    const itemBySku = supportCase.items.find((sc) => sc.product.sku === sku);
    if (!itemBySku) throw new Error("This should not happen. You're deleting an item that is not in the items list");

    dispatchSupportCaseAction({
      type: "update-item",
      item: itemBySku,
      itemAction: { type: "reset-item" },
    });
  }

  function handleSelectClick(e: React.MouseEvent<HTMLDivElement>, sku: string): void {
    e.preventDefault();
    const itemBySku = supportCase.items.find((sc) => sc.product.sku === sku);
    if (!itemBySku) throw new Error("This should not happen. You're selecting an item that is not in the items list");

    dispatchSupportCaseAction({
      type: "select-item",
      item: itemBySku,
    });
  }

  return (
    <>
      <div className="pb-4 mx-auto lg:max-w-none">
        <div className={`px-8 py-8 ${borderWidth} border-white`}>
          <h3 className={`mb-2 ${style.headline2}`}>Meine Anfrage</h3>
          <p className={`${style.paragraph}`}>
            Bestellnummer: {supportCase.ordernumber} <br />
            {supportCase.invoiceDate &&
              "Rechnungsdatum: " +
                LocalDate.parse(supportCase.invoiceDate).format(DateTimeFormatter.ofPattern("d.M.yyyy"))}
          </p>
        </div>
        <div>
          <ul>
            <AnimatePresence>
              {supportCase.items
                // Also keep 0 quantity?
                // sc === supportCase.selectedItem || sc.returnQuantity !== 0
                .filter((sc) => sc.returnQuantity !== undefined && sc.returnQuantity > 0)
                .map((sc) => (
                  <motion.li
                    key={sc.product.sku}
                    className={`flex justify-between mb-6 ${borderWidth} ${
                      sc.product.sku === supportCase.selectedItem?.product.sku
                        ? "border-primary-500"
                        : "border-transparent"
                    }`}
                    initial={{ opacity: 0, y: 10 }}
                    animate={{ opacity: 1, y: 0 }}
                    exit={{ opacity: 0, y: -20, transition: { duration: 0.1 } }}
                  >
                    <div
                      tabIndex={0}
                      onClick={(e) => handleSelectClick(e, sc.product.sku)}
                      className={`focus:border-white focus:outline-none focus:ring-0 focus:ring-black cursor-pointer pl-8`}
                    >
                      <h4 className={`${style.headlineBodyText}`}>
                        <motion.span
                          className="inline-block"
                          key={sc.returnQuantity}
                          initial={{ scale: 1.3 }}
                          animate={{
                            scale: 1,
                          }}
                          transition={{ duration: 0.2 }}
                        >
                          {sc.returnQuantity}
                        </motion.span>
                        x&nbsp;{sc.product.title}
                      </h4>
                      <div>
                        <ul className="mt-3">
                          {sc.reason && (
                            <li className={`${style.headlineMeta} mb-1 flex`}>
                              <QuestionMarkCircleIcon className={`w-6 h-6 mr-3 ${style.colorNormal}`} />
                              {getLocalizedStringFromReasons(reasonsWithSolutions, sc.reason)}
                            </li>
                          )}
                          {sc.solution !== undefined && sc.reason !== undefined && (
                            <li className={`${style.headlineMeta} mb-1 flex`}>
                              <LightBulbIcon className={`w-6 h-6 mr-3 ${style.colorNormal}`} />
                              {getLocalizedSolutionStringFromReasons(reasonsWithSolutions, sc.reason, sc.solution)}
                            </li>
                          )}
                        </ul>
                      </div>
                    </div>
                    <div className="flex">
                      <button
                        type="button"
                        className="inline-flex items-center px-6 py-1 focus:outline-none focus:ring-2 focus:ring-black"
                        onClick={(e) => handleDeleteClick(e, sc.product.sku)}
                      >
                        <TrashIcon className="w-5 h-5" aria-hidden="true" />
                      </button>
                    </div>
                  </motion.li>
                ))}
            </AnimatePresence>
          </ul>
        </div>
      </div>
    </>
  );
};
