import React, { useState } from "react";
import { Reason } from "../../model/Reason";
import { SupportCaseState, SupportCaseStateAction } from "../state/SupportCaseState";
import { Button } from "./Button";
import { ConfirmDialog } from "./ConfirmDialog";
import { EmptySolutionDialog } from "./EmptySolutionDialog";
import { Logout } from "./Logout";
import { ScrollToTop } from "./ScrollToTop";

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface EditorFooterActionsProps {
  supportCase: SupportCaseState;
  dispatchSupportCaseAction: (supportCaseAction: SupportCaseStateAction) => void;
  reasonsWithSolutions: Reason[];
}

export const EditorFooterActions: React.FC<EditorFooterActionsProps> = ({
  supportCase,
  dispatchSupportCaseAction,
  reasonsWithSolutions,
}) => {
  const [isOpenConfirmDialog, setIsOpenConfirmDialog] = useState(false);
  const [isOpenEmptySolutionDialog, setIsOpenEmptySolutionDialog] = useState(false);

  return (
    <div className="grid grid-cols-1 gap-4 xl:grid-cols-2">
      {supportCase.items.length > 1 ? (
        <ScrollToTop>
          <Button variant="text">Weiteres Produkt hinzufügen...</Button>
        </ScrollToTop>
      ) : (
        <div></div>
      )}
      <div className="grid grid-cols-1 gap-4 lg:grid-cols-2">
        <ConfirmDialog
          reasonsWithSolutions={reasonsWithSolutions}
          supportCase={supportCase}
          dispatchSupportCaseAction={dispatchSupportCaseAction}
          isOpen={isOpenConfirmDialog}
          setOpen={() => setIsOpenConfirmDialog(!isOpenConfirmDialog)}
        ></ConfirmDialog>
        <EmptySolutionDialog
          isOpen={isOpenEmptySolutionDialog}
          setOpen={setIsOpenEmptySolutionDialog}
          supportCase={supportCase}
          dispatchSupportCaseAction={dispatchSupportCaseAction}
        />
        <div className="lg:order-last">
          <Button
            isBlock
            variant="primary"
            disabled={!supportCase.items.some((item) => item.returnQuantity && item.solution)}
            onClick={() => {
              if (supportCase.items.some((item) => item.returnQuantity && !item.solution)) {
                setIsOpenEmptySolutionDialog(!isOpenEmptySolutionDialog);
              } else {
                setIsOpenConfirmDialog(!isOpenConfirmDialog);
              }
            }}
          >
            Weiter...
          </Button>
        </div>
        <div>
          <Logout>Logout</Logout>
        </div>
      </div>
    </div>
  );
};
