import React, { HTMLAttributes } from "react";

export type ContainerProps = HTMLAttributes<HTMLDivElement>;

export const Container: React.FC<ContainerProps> = ({ children, ...props }) => {
  return (
    <div className="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8" {...props}>
      {children}
    </div>
  );
};
