import React, { ChangeEvent } from "react";
import { SupportCaseStateItem } from "../state/SupportCaseItemState";
import { style } from "../styles/styles";
import { Alert } from "./Alert";

interface ComplaintUploadProps {
  supportCaseItem: SupportCaseStateItem;
  handleChange: (e: ChangeEvent<HTMLInputElement>) => void;
  isUploading: boolean;
  isFileError: boolean;
}

export const ComplaintUpload: React.FC<ComplaintUploadProps> = ({
  handleChange,
  isUploading,
  supportCaseItem,
  isFileError,
}) => {
  return (
    <>
      {supportCaseItem?.complaintImages && supportCaseItem?.complaintImages.length >= 8 ? (
        <>
          <div className="mt-10">
            <p className={`${style.colorNormal} font-medium`}>
              Vielen Dank für die Fotos. Die maximale Anzahl ist erreicht.
            </p>
          </div>
        </>
      ) : (
        <>
          <div className="mt-10">
            <label
              htmlFor="file-upload"
              className="flex flex-col justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md cursor-pointer hover:text-primary-500 focus-within:border-transparent focus-within:outline-none focus-within:ring-2 focus-within:ring-primary-500"
            >
              {isUploading ? (
                // <Loader isFullHeight={false} />
                <div className={`${style.colorNormal} py-8 text-sm text-center`}>
                  Bilder werden hochgeladen. Einen Moment bitte...
                </div>
              ) : (
                <div className="space-y-1 text-center">
                  <svg
                    className={`w-12 h-12 mx-auto ${style.colorMuted}`}
                    stroke="currentColor"
                    fill="none"
                    viewBox="0 0 48 48"
                    aria-hidden="true"
                  >
                    <path
                      d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                      strokeWidth={2}
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                  <div className={`${style.colorNormal} text-center`}>
                    <div className={`${style.colorPrimary} relative  bg-white rounded-md cursor-pointer`}>
                      {supportCaseItem.reason === "damagedByCarrier" ? (
                        <span>Lade ein Bild vom Versandlabel hoch und Bilder, die den Schaden zeigen.</span>
                      ) : supportCaseItem.product.title.toLowerCase().includes("schlafsack") ? (
                        <span>
                          ⚠️ Für die Rückgabe eines Schlafsacks benötigen wir zusätzlich auch ein Bild der Innenseite
                          des Reißverschlusses
                        </span>
                      ) : (
                        <span>Lade Bilder hoch, die das Problem zeigen</span>
                      )}
                      <input
                        id="file-upload"
                        onChange={handleChange}
                        name="file-upload"
                        type="file"
                        className="sr-only"
                      />
                    </div>
                  </div>
                  <p className={`${style.headlineMeta}`}>PNG, JPG, GIF bis 25MB</p>
                </div>
              )}
            </label>
          </div>
          <div className="mt-6">
            {isFileError ? (
              <Alert variant="error">
                <span className="">Bitte nur PNG, JPG, GIF bis 25MB hochladen.</span>
              </Alert>
            ) : (
              <></>
              // <Alert variant='notice'>
              //   <span className=''>Die Fotos dürfen 10 MB nicht überschreiten.</span>
              // </Alert>
            )}
          </div>
        </>
      )}
    </>
  );
};
