import React from "react";
import { useAuthState } from "../../contexts/AuthContext";
import { SupportCaseStateItem } from "../../state/SupportCaseItemState";
import { SupportCaseStateAction } from "../../state/SupportCaseState";
import { style } from "../../styles/styles";
import { ListBox, Option } from "../ListBox";
import { SectionHeadline } from "../SectionHeadline";

interface BatchNumberProps {
  supportCaseItem: SupportCaseStateItem;
  dispatchSupportCaseAction: (supportCaseAction: SupportCaseStateAction) => void;
}

export const BatchNumberEditor: React.FC<BatchNumberProps> = ({
  supportCaseItem,
  dispatchSupportCaseAction,
}): JSX.Element => {
  const authState = useAuthState();

  if (authState.authStatus !== "authenticated") throw new Error("rendering ReasonEditor but we are not authenticated");

  function selectedBatchNumber(): Option {
    const found = supportCaseItem.product.batchNumberOptions?.find((item) => item === supportCaseItem.batchNumber);
    if (found) {
      return {
        label: found,
        value: found,
      };
    } else {
      return {
        value: "notset",
        label: "Bitte wählen",
      };
    }
    // auto select if only one item
    // if (supportCaseItem.product.batchNumberOptions?.length === 1) {
    //   return {
    //     label: supportCaseItem.product.batchNumberOptions[0],
    //     value: supportCaseItem.product.batchNumberOptions[0],
    //   };
    // } else {

    // }
  }

  return (
    <section>
      <SectionHeadline title="Wähle die Chargennummer aus:" />
      {/* <SectionSubHeadline subtitle={supportCaseItem.product.title} /> */}
      <p className={`${style.paragraph} mb-4`}>
        {supportCaseItem.product.batchNumberDescription?.[0]?.value
          ? supportCaseItem.product.batchNumberDescription?.[0].value
          : ""}
      </p>
      <div className="w-[21rem]">
        <ListBox
          items={
            supportCaseItem.product.batchNumberOptions?.map((item) => ({
              label: item,
              value: item,
            })) as Option[]
          }
          selected={selectedBatchNumber()}
          setSelected={(batchNumber: string): void => {
            dispatchSupportCaseAction({
              type: "update-item",
              item: supportCaseItem,
              itemAction: {
                type: "change-batchNumber",
                batchNumber,
              },
            });
          }}
        />
      </div>
    </section>
  );
};
