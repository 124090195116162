import React from "react";
import { Reason } from "../../../model/Reason";
import { Solution } from "../../../model/Solution";
import { SupportCaseStateItem } from "../../state/SupportCaseItemState";
import { SupportCaseStateAction } from "../../state/SupportCaseState";
import { SectionHeadline } from "../SectionHeadline";
import { StackedRadioCard, StackedRadioCards } from "../StackedRadioCards";

interface SolutionEditorProps {
  supportCaseItem: SupportCaseStateItem;
  dispatchSupportCaseAction: (supportCaseAction: SupportCaseStateAction) => void;
  reasonsWithSolutions: Reason[];
}

export const SolutionEditor: React.FC<SolutionEditorProps> = ({
  supportCaseItem,
  dispatchSupportCaseAction,
  reasonsWithSolutions,
}): JSX.Element => {
  const possibleSolutions = reasonsWithSolutions.find((item) => item.name === supportCaseItem.reason)
    ?.solutions as unknown as Solution[];

  const solutionsForUi: StackedRadioCard[] =
    possibleSolutions
      ?.map((item) => ({
        value: item.name,
        label: item.title?.[0].value ?? "title not set",
        description: item.description?.[0].value ?? "",
        isCheckedNotActive: false,
        sorting: item.sorting || 0,
      }))
      .sort((a, b) => (a.sorting > b.sorting ? 1 : -1)) || [];

  return (
    <section>
      <SectionHeadline title="Wähle eine Lösung:" />
      {/* <SectionSubHeadline subtitle={supportCaseItem.product.title} /> */}
      <StackedRadioCards
        title="Lösung"
        items={solutionsForUi}
        selected={solutionsForUi.find((item) => item.value === supportCaseItem.solution)?.value ?? "notset"}
        setSelected={(solution: string): void => {
          dispatchSupportCaseAction({
            type: "update-item",
            item: supportCaseItem,
            itemAction: {
              type: "change-solution",
              solution,
            },
          });
        }}
      />
    </section>
  );
};
