import { Reason } from "../../model/Reason";
import { SupportCaseStatus } from "../../model/SupportCase";
import { ApiClient } from "../../utils/ApiClient";
import { IS_APP_ENV_PRODUCTION } from "../../utils/constantsPublic";
import { getForeignCountryReturnInfo, getTippContent } from "../../utils/content";
import { localStore } from "../localStore";
import { SupportCaseState } from "../state/SupportCaseState";
import { style } from "../styles/styles";
import { AutoLogoutForward } from "./AutoLogoutForward";
import { Button } from "./Button";
import Layout from "./Layout";
import { Logout } from "./Logout";
import { SummaryListing } from "./SummaryListing";

interface SummaryProps {
  supportCase: SupportCaseState;
  reasonsWithSolutions: Reason[];
}

export const Summary: React.FC<SummaryProps> = ({ supportCase, reasonsWithSolutions }) => {
  return (
    <Layout>
      <div className="mt-16 mb-8 ">
        <h1 className={`${style.headline1} mb-4`}>
          {supportCase.status === SupportCaseStatus.Shipping
            ? "Deine Retoure"
            : supportCase.status === SupportCaseStatus.Canceled
            ? "Die Bestellung wurde storniert"
            : "Deine Anfrage"}
        </h1>
        <div className={`${style.paragraph} mb-8`}>
          {supportCase.status === SupportCaseStatus.Shipping ? (
            <>
              <p className={`${style.paragraphMargin}`}>
                {supportCase.country === "DE"
                  ? "Danke! Du erhältst die Links zu den Retoure Labels via E-Mail oder kannst sie dir direkt hier ausdrucken."
                  : "Danke für deine Anfrage. Du erhälst nochmals alle Informationen via E-Mail."}
              </p>
              {supportCase.country !== "DE" && (
                <div className="mb-3">{getForeignCountryReturnInfo(true, { className: "space-y-4" })}</div>
              )}
              {supportCase.items &&
              supportCase.items.filter((item) => item.solution)?.length > 1 &&
              supportCase.country === "DE" ? (
                <p className={`${style.paragraphMargin}`}>{getTippContent(true)}</p>
              ) : (
                <></>
              )}
            </>
          ) : supportCase.status === SupportCaseStatus.Canceled ? (
            <>
              Gerne kannst du eine neue Bestellung auf{" "}
              <a className={style.paragraphLink} target="_blank" href="https://www.ehrenkind.de" rel="noreferrer">
                ehrenkind.de
              </a>{" "}
              aufgeben.
            </>
          ) : (
            <>
              <p className={`${style.paragraphMargin}`}>
                Danke! Wir haben Deine Anfrage erhalten. Nach der Prüfung informieren wir dich via E-Mail.
              </p>
            </>
          )}
          <p className={`${style.paragraphMargin}`}>
            <AutoLogoutForward />
          </p>
        </div>
      </div>
      <SummaryListing supportCase={supportCase} reasonsWithSolutions={reasonsWithSolutions} />
      <div className="flex justify-end my-12">
        <div className="max-w-md">
          <Logout>Logout</Logout>
        </div>
        {!IS_APP_ENV_PRODUCTION && (
          <div className="ml-4">
            <Button
              variant="rainbow"
              onClick={async (e) => {
                e.preventDefault();
                try {
                  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                  await ApiClient.withXAuthToken(localStore.getAuthToken()!).delete(
                    `/api/support-cases/${supportCase.ordernumber}`,
                  );
                  location.reload();
                } catch (error) {
                  console.error(error);
                }
              }}
            >
              DEV only: delete support case
            </Button>
          </div>
        )}
      </div>
    </Layout>
  );
};
