import React from "react";
import { Reason } from "../../../model/Reason";
import { SupportCaseStateItem } from "../../state/SupportCaseItemState";
import { SupportCaseStateAction } from "../../state/SupportCaseState";
import { ListBox } from "../ListBox";
import { SectionHeadline } from "../SectionHeadline";

interface ReasonEditorProps {
  supportCaseItem: SupportCaseStateItem;
  dispatchSupportCaseAction: (supportCaseAction: SupportCaseStateAction) => void;
  reasonsWithSolutions: Reason[];
}

export const ReasonEditor: React.FC<ReasonEditorProps> = ({
  supportCaseItem,
  dispatchSupportCaseAction,
  reasonsWithSolutions,
}): JSX.Element => {
  const reasonsForUi =
    reasonsWithSolutions
      ?.map((item) => ({
        value: item.name,
        label: item.description?.[0]?.value ?? "missing title",
        sorting: item.sorting || 0,
      }))
      .sort((a, b) => (a.sorting > b.sorting ? 1 : -1)) || [];

  return (
    <section>
      <SectionHeadline title="Was ist der Grund für deine Anfrage?" />
      {/* <SectionSubHeadline subtitle={supportCaseItem.product.title} /> */}
      <div className="max-w-lg">
        <ListBox
          items={reasonsForUi}
          selected={
            reasonsForUi.find((reason) => reason.value === supportCaseItem.reason) ?? {
              value: "notset",
              label: "Bitte wählen",
            }
          }
          setSelected={(reason: string): void => {
            // get whole reason object first to figure out if complaint
            const isComplaint = reasonsWithSolutions?.find((item) => item.name === reason)?.isComplaint;
            if (isComplaint === undefined)
              throw new Error(
                `This should never happen. Could not determine if current reason (${reason}) is a complaint`,
              );

            dispatchSupportCaseAction({
              type: "update-item",
              item: supportCaseItem,
              itemAction: {
                type: "change-reason",
                reason,
                isComplaint,
                // Maybe leave this, so old changes are still visible in the UI
                // If not, then we should remove everything, incl. complaintDescription
                solution: undefined,
              },
            });
          }}
        />
      </div>
    </section>
  );
};
