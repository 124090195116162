import React, { useEffect, useState } from "react";

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface ScrollToTopProps {}

export const ScrollToTop: React.FC<ScrollToTopProps> = ({ children }) => {
  const [isVisible, setIsVisible] = useState(false);

  // Top: 0 takes us all the way back to the top of the page
  // Behavior: smooth keeps it smooth!
  const scrollToTop = (): void => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    // Button is displayed after scrolling for 500 pixels
    const toggleVisibility = (): void => {
      if (window.pageYOffset > 100) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };

    window.addEventListener("scroll", toggleVisibility);

    return () => window.removeEventListener("scroll", toggleVisibility);
  }, []);

  //scroll-to-top classes: fixed, bottom:0, right:0
  return <div className="scroll-to-top">{isVisible && <div onClick={scrollToTop}>{children}</div>}</div>;
};
