import { ExclamationCircleIcon } from "@heroicons/react/solid";
import React from "react";
import { style } from "../styles/styles";

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface ReasonExpiredProps {}

export const ReasonExpired: React.FC<ReasonExpiredProps> = ({}) => {
  return (
    <div className="!mt-6">
      <p className={`inline-flex items-center ${style.paragraph} ${style.colorPrimary}`}>
        <span className="inline-flex w-6 h-6 mr-3">
          <ExclamationCircleIcon />{" "}
        </span>
        Die Widerrufsfrist für diese Bestellung ist abgelaufen
      </p>
    </div>
  );
};
