import { CheckAuth } from "./CheckAuth";
import { Container } from "./Container";
import { Footer } from "./Footer";
import { Header } from "./Header";
import ProtectedRoute from "./ProtectedRoute";

interface LayoutProps {
  isLoading?: boolean;
}

const Layout: React.FC<LayoutProps> = ({ children, isLoading }) => {
  return (
    <>
      <CheckAuth>
        <ProtectedRoute>
          <Header title="Serviceportal" />
          <Container>
            {children}
            {!isLoading && (
              <div className="justify-end py-4">
                <Footer />
              </div>
            )}
          </Container>
        </ProtectedRoute>
      </CheckAuth>
    </>
  );
};

export default Layout;
