import Image, { ImageProps } from "next/image";
import React from "react";
import { GenericImage } from "../../model/GenericImage";

interface ImageWithFallbackProps extends Partial<ImageProps> {
  image?: GenericImage;
}

export const ImageWithFallback: React.FC<ImageWithFallbackProps> = ({ image, ...props }) => {
  return image ? (
    <Image {...props} src={image.src} alt={image.alt} width={100} height={100} objectFit="contain" />
  ) : (
    <div className="flex items-center justify-center bg-white">
      <Image
        {...props}
        src="/Ehrenkind_Logo_lines_vertical_noMargin.svg"
        alt="Ehrenkind"
        width={80}
        height={80}
        className="p-4 opacity-40"
        objectFit="contain"
        // placeholder="blur"
        // blurDataURL="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAADUlEQVR42mP8z8BQDwAEhQGAhKmMIQAAAABJRU5ErkJggg=="
      />
    </div>
  );
};
