import React from "react";
import { SupportCaseStateItem } from "../../state/SupportCaseItemState";
import { SupportCaseStateAction } from "../../state/SupportCaseState";
import { FieldNumberButtons } from "../FieldNumberButtons";
import { SectionHeadline } from "../SectionHeadline";

interface ReturnQuantityEditorProps {
  supportCaseItem: SupportCaseStateItem;
  dispatchSupportCaseAction: (supportCaseAction: SupportCaseStateAction) => void;
}

export const ReturnQuantityEditor: React.FC<ReturnQuantityEditorProps> = ({
  supportCaseItem,
  dispatchSupportCaseAction,
}): JSX.Element => {
  if (supportCaseItem === undefined) {
    throw new Error("this should not happen. please select an order item first");
  }
  return (
    <section>
      <SectionHeadline title="Wie viele Artikel von dieser Position sind betroffen?" />
      {/* <SectionSubHeadline subtitle={supportCaseItem.product.title} /> */}
      <div className="w-52">
        <FieldNumberButtons
          val={supportCaseItem.returnQuantity}
          max={supportCaseItem.purchaseQuantity}
          onValChange={(newValue) =>
            dispatchSupportCaseAction({
              type: "update-item",
              item: supportCaseItem,
              itemAction: {
                type: "change-return-quantity",
                quantity: newValue,
              },
            })
          }
        />
      </div>
    </section>
  );
};
