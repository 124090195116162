import React, { HtmlHTMLAttributes } from "react";
import { classNames } from "../../utils/helper";

type LoaderProps = HtmlHTMLAttributes<HTMLDivElement> & {
  isFullHeight?: boolean;
  isOverlay?: boolean;
};

export const Loader: React.FC<LoaderProps> = ({ isFullHeight, isOverlay, children, ...props }) => {
  return (
    <>
      <>
        <div
          className={classNames(
            isFullHeight ? "min-h-screen" : "",
            isOverlay ? "bg-white/70 fixed w-full h-full left-0 top-0 z-30" : "",
            "flex items-center justify-center ",
            props.className ? props.className : "",
          )}
        >
          <div className="flex p-5 space-x-3 rounded-full loader">
            <div className="w-5 h-5 rounded-full bg-primary-500 animate-bounce "></div>
            <div className="w-5 h-5 rounded-full bg-primary-500 animate-bounce "></div>
            <div className="w-5 h-5 rounded-full bg-primary-500 animate-bounce "></div>
          </div>
          {children}
        </div>
      </>
    </>
  );
};
