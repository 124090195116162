import React, { InputHTMLAttributes } from "react";
import { classNames } from "../../utils/helper";
import { Field } from "./Field";

type FieldNumberButtonsProps = Omit<InputHTMLAttributes<HTMLInputElement>, "type"> & {
  val: number;
  max: number;
  onValChange: (newVal: number) => void;
};

const inputButtonClasses =
  "relative inline-flex items-center px-4 space-x-2 font-extrabold text-gray-400 border focus:border-white focus:outline-none focus:ring-2 focus:ring-primary-600 border-gray-300";

export const FieldNumberButtons: React.FC<FieldNumberButtonsProps> = ({ val, max, onValChange, ...props }) => {
  function calcAmount(val: number, maxAmount: number): number {
    return val <= maxAmount && val >= 0 ? val : val <= 0 ? 0 : maxAmount;
  }

  function handleAmountChange(e: React.ChangeEvent<HTMLInputElement>): void {
    onValChange(calcAmount(parseInt(e.target.value), max));
  }

  function handleAddSubtract(delta: number): void {
    onValChange(calcAmount(val + delta, max));
  }

  return (
    <div className="custom-number-input">
      <Field
        variant="primary"
        controlId="controlId"
        onChange={handleAmountChange}
        disabled
        name="Name"
        value={val.toString()}
        type="text"
        min="0"
        buttonLeft={
          <button
            onClick={() => handleAddSubtract(-1)}
            className={classNames("rounded-l-md border-r-0 focus:z-10", inputButtonClasses)}
          >
            <span>-</span>
          </button>
        }
        buttonRight={
          <button
            onClick={() => handleAddSubtract(+1)}
            className={classNames("rounded-r-md border-l-0", inputButtonClasses)}
          >
            <span>+</span>
          </button>
        }
        additionalInputClasses="text-center z-[-1] !rounded-none !border-gray-300 !bg-white"
        {...props}
      />
    </div>
  );
};
