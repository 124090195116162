import React from "react";
import { gray } from "tailwindcss/colors";
const primary = require("../../../primaryColor");
// no state: open circle
// isSelected: Checkmark
// isCurrent: Checkmark in color
interface CheckmarkProps {
  isSelected?: boolean;
  isCurrent?: boolean;
}

export const Checkmark: React.FC<CheckmarkProps> = ({ isCurrent, isSelected }) => {
  const noFill = { fill: "#fff", opacity: "0" };
  const defaultFill = { fill: gray[200] };
  const inactiveFill = { fill: gray[400] };
  const currentFill = { fill: primary[500] };

  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <title>checkmark-circle</title>
      <g id="Layer_2" data-name="Layer 2">
        <g id="checkmark-circle">
          <g id="checkmark-circle-2" data-name="checkmark-circle">
            <rect style={noFill} width="24" height="24" />

            {isSelected || isCurrent ? (
              <>
                {/* inner */}
                <path
                  style={isCurrent ? currentFill : inactiveFill}
                  d="M9.71,11.29a1,1,0,0,0-1.42,1.42l3,3A1,1,0,0,0,12,16h0a1,1,0,0,0,.72-.34l7-8a1,1,0,0,0-1.5-1.32L12,13.54Z"
                />
                {/* outer */}
                <path
                  style={isCurrent ? currentFill : inactiveFill}
                  d="M21,11h0a1,1,0,0,0-1,1,8,8,0,0,1-8,8h0A8,8,0,0,1,6.33,6.36,7.93,7.93,0,0,1,12,4a8.79,8.79,0,0,1,1.9.22,1,1,0,1,0,.47-1.94A10.54,10.54,0,0,0,12,2,10,10,0,0,0,5,19.09,9.93,9.93,0,0,0,12,22h0A10,10,0,0,0,22,12,1,1,0,0,0,21,11Z"
                />
              </>
            ) : (
              <path
                style={defaultFill}
                d="M12,2c-5.5,0-10,4.5-10,10s4.5,10,10,10c5.5,0,10-4.5,10-10S17.5,2,12,2z M12,20c-4.4,0-8-3.6-8-8c0-4.4,3.6-8,8-8s8,3.6,8,8C20,16.4,16.4,20,12,20z"
              />
            )}
          </g>
        </g>
      </g>
    </svg>
  );
};
