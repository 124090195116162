import React from "react";
import { SupportCaseState, SupportCaseStateAction } from "../../state/SupportCaseState";
import { style } from "../../styles/styles";
import { SectionHeadline } from "../SectionHeadline";
import { StackedRadioCard, StackedRadioCards } from "../StackedRadioCards";

interface OrderItemsEditorProps {
  supportCase: SupportCaseState;
  dispatchSupportCaseAction: (supportCaseAction: SupportCaseStateAction) => void;
}

export const OrderItemsEditor: React.FC<OrderItemsEditorProps> = ({
  supportCase,
  dispatchSupportCaseAction,
}): JSX.Element => {
  const cards: StackedRadioCard[] = supportCase.items.map((item) => ({
    value: item.product.sku,
    label: item.product.title,
    image: item.product.images[0],
    description: `Bestellmenge&nbsp;${item.purchaseQuantity}`,
    isCheckedNotActive: Boolean(item.returnQuantity > 0),
    withImage: true,
  }));

  return (
    <section>
      <SectionHeadline title="Wähle die gewünschte Position:" additionalClassNames="mt-6 !mb-2" />
      <div className="mb-8 ">
        <p className={`${style.paragraph} mb-2`}>
          Bitte wähle das Produkt aus, für das du eine Serviceanfrage einreichen möchtest.
        </p>
        <p className={`${style.paragraph}`}>
          <strong>Du möchtest ein Produkt umtauschen?</strong> Wähle bitte weiter unten den Rücksendegrund und deine
          gewünschte Lösung aus und bestelle den Ersatzartikel einfach über unseren Shop in der gewünschten Ausführung.{" "}
        </p>
      </div>
      <StackedRadioCards
        title="Choose order items"
        items={cards}
        selected={supportCase.selectedItem?.product.sku}
        setSelected={(sku: string | undefined): void => {
          dispatchSupportCaseAction({
            type: "select-item",
            item: supportCase.items.find((item) => item.product.sku === sku),
          });
        }}
      />
    </section>
  );
};
