import React, { useState } from "react";
import { GenericImage } from "../../../model/GenericImage";
import { ApiClient } from "../../../utils/ApiClient";
import { useAuthState } from "../../contexts/AuthContext";
import { SupportCaseStateItem } from "../../state/SupportCaseItemState";
import { SupportCaseStateAction } from "../../state/SupportCaseState";
import { ComplaintDescription } from "../ComplaintDescription";
import { ComplaintImages } from "../ComplaintImages";
import { ComplaintUpload } from "../ComplaintUpload";
import { SectionHeadline } from "../SectionHeadline";
import { ComplaintImagesLoadingState } from "./SupportCaseEditor";

interface ComplaintEditorProps {
  supportCaseItem: SupportCaseStateItem;
  dispatchSupportCaseAction: (supportCaseAction: SupportCaseStateAction) => void;
  handleIsUploadingInProgress: (status: ComplaintImagesLoadingState) => void;
  isUploading: boolean;
  handleAreAllImagesFullyLoaded: () => void;
}

export const ComplaintEditor: React.FC<ComplaintEditorProps> = ({
  dispatchSupportCaseAction,
  supportCaseItem,
  handleIsUploadingInProgress,
  isUploading,
  handleAreAllImagesFullyLoaded,
}) => {
  const authState = useAuthState();
  if (authState.authStatus !== "authenticated") throw new Error("This should never throw");
  const client = ApiClient.withXAuthToken(authState.authToken);
  const { ordernumber } = authState.user;
  const [isFileError, setIsFileError] = useState(false);

  return (
    <section>
      <SectionHeadline title="Beschreibe das Problem näher:" />
      {/* <SectionSubHeadline subtitle={supportCaseItem.product.title} /> */}

      <ComplaintDescription
        value={supportCaseItem.complaintDescription ?? ""}
        handleChange={(event): void => {
          dispatchSupportCaseAction({
            type: "update-item",
            item: supportCaseItem,
            itemAction: {
              type: "change-complaintDescription",
              complaintDescription: event.target.value,
            },
          });
        }}
      />

      <ComplaintUpload
        supportCaseItem={supportCaseItem}
        isFileError={isFileError}
        handleChange={(e) => {
          setIsFileError(false);
          handleIsUploadingInProgress("UPLOADING");
          const files = e.target.files;
          if (!files?.length) {
            handleIsUploadingInProgress("CANCELED");
            return;
          }
          if (Array.from(files).some((file) => file.size / 1024 / 1024 > 25 || !file.type.includes("image/"))) {
            setIsFileError(true);
            handleIsUploadingInProgress("CANCELED");
          } else {
            for (const file of Array.from(files)) {
              const reader = new FileReader();
              reader.readAsDataURL(file);
              reader.onloadend = async () => {
                try {
                  // upload file here
                  const complaintImage = await client.post<GenericImage>(`/api/upload/${ordernumber}`, {
                    data: {
                      file: reader.result,
                      sku: supportCaseItem.product.sku,
                      batchNumber: supportCaseItem.batchNumber || "",
                    },
                  });
                  // store urls to state
                  dispatchSupportCaseAction({
                    type: "update-item",
                    itemAction: {
                      type: "change-complaintUpload",
                      complaintImage,
                    },
                    item: supportCaseItem,
                  });
                } catch (error) {
                  console.error(error);
                }
                handleIsUploadingInProgress("UPLOADED");
              };
            }
          }
        }}
        isUploading={isUploading}
      />

      <ComplaintImages
        images={supportCaseItem.complaintImages}
        handleAreAllImagesFullyLoaded={handleAreAllImagesFullyLoaded}
        handleDelete={async (e, id) => {
          e.preventDefault();
          try {
            dispatchSupportCaseAction({
              type: "update-item",
              item: supportCaseItem,
              itemAction: { type: "delete-complaintUpload", complaintImageId: id },
            });
            await client.delete(`/api/upload/${ordernumber}`, {
              data: {
                id,
              },
            });
          } catch (error) {
            console.error(error);
          }
        }}
      />
    </section>
  );
};
