import { LightBulbIcon, PrinterIcon, QuestionMarkCircleIcon } from "@heroicons/react/solid";
import React from "react";

import { Reason } from "../../model/Reason";
import { getLocalizedSolutionStringFromReasons, getLocalizedStringFromReasons } from "../../utils/getLocalizedString";
import { getParcelServiceText } from "../../utils/getParcelServiceContent";
import { SupportCaseState } from "../state/SupportCaseState";
import { style } from "../styles/styles";
import { Alert } from "./Alert";
import { Button } from "./Button";
import { ImageWithFallback } from "./ImageWithFallback";

interface SummaryListingProps {
  supportCase: SupportCaseState;
  reasonsWithSolutions: Reason[];
}

export const SummaryListing: React.FC<SummaryListingProps> = ({ supportCase, reasonsWithSolutions }) => {
  return (
    <>
      <div className="relative -space-y-px">
        {supportCase?.items
          .filter((item) => item.returnQuantity > 0)
          ?.filter((item) => item.solution)
          .map((item) => {
            return (
              <div
                key={item.product.sku}
                className="relative grid py-8 border-t border-gray-200 radioTextCard sm:grid-cols-radioImageTextCard"
              >
                <div className="flex justify-center mb-6 sm:justify-start sm:mb-0">
                  <ImageWithFallback image={item.product.images[0]} objectPosition="top" />
                </div>

                <div className="">
                  <h3 className={`${style.headline3} mb-4`}>
                    {item.returnQuantity}x {item.product.title}
                  </h3>
                  <div className={`${style.paragraph} mb-1`}>
                    <ul className="mt-2 ">
                      {item.reason && (
                        <li className={`mb-2 flex`}>
                          <QuestionMarkCircleIcon className={`w-6 h-6 mr-3 ${style.colorNormal}`} />
                          <div>{getLocalizedStringFromReasons(reasonsWithSolutions, item.reason)}</div>
                        </li>
                      )}
                      {item.solution !== undefined && item.reason !== undefined && (
                        <li className={`mb-2 flex`}>
                          <LightBulbIcon className={`w-6 h-6 mr-3 ${style.colorNormal}`} />
                          <div>
                            {getLocalizedSolutionStringFromReasons(reasonsWithSolutions, item.reason, item.solution)}
                          </div>
                        </li>
                      )}
                    </ul>

                    {Boolean(!item.isComplaint) && Boolean(item.returnFee) && (
                      <>
                        {supportCase.country === "DE" ? (
                          <p className={`mt-6 mb-1`}>
                            Es fallen {((item.returnFee ?? 0) * item.returnQuantity).toFixed(2).replace(".", ",")} €
                            Rückversandkosten an {item.product.returnNotice?.[0]?.value ? "*" : ""}
                          </p>
                        ) : (
                          <></>
                        )}
                      </>
                    )}
                    <div className={`mt-5 mb-1`}>
                      {Boolean(item.product?.returnConditions?.[0]?.value) &&
                        !item.isComplaint &&
                        (item.solution === "returnWithRefund" ||
                          item.solution === "returnWithoutRefund" ||
                          item.solution === "replacement") && (
                          <Alert variant="notice">
                            Rücksendebedingungen: {item.product?.returnConditions?.[0]?.value}
                          </Alert>
                        )}
                    </div>
                    <div className="mt-10">
                      {supportCase.country === "DE"
                        ? Boolean(item.returnParcelService?.isSelfService) &&
                          item.returnFee === item.product.returnFee &&
                          !Boolean(item.isComplaint) && (
                            <>
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: getParcelServiceText(item, "de_DE")[0].replaceAll(
                                    "<p>",
                                    `<p class="${style.paragraphMargin}">`,
                                  ),
                                }}
                                className="mb-1"
                              ></div>

                              <div className="flex justify-center mt-8 mb-8">
                                <Button
                                  variant="primary"
                                  href={item.returnParcelService?.returnsLabelLink}
                                  isExternalLink
                                >
                                  <span className="w-4 h-4 mr-2">
                                    <PrinterIcon />
                                  </span>
                                  {`Rücksendelabel drucken`}
                                </Button>
                              </div>

                              <div
                                dangerouslySetInnerHTML={{
                                  __html: (getParcelServiceText(item, "de_DE")[1]
                                    ? getParcelServiceText(item, "de_DE")[1]
                                    : ""
                                  ).replaceAll("<p>", `<p class="${style.paragraphMargin}">`),
                                }}
                              ></div>
                            </>
                          )
                        : ""}
                    </div>

                    {Boolean(!item.isComplaint) && Boolean(item.returnFee) && Boolean(supportCase.country === "DE") && (
                      <div className="mt-6">
                        <span className={`text-sm ${style.colorMuted}`}>
                          {item.product.returnNotice?.[0]?.value ? "* " + item.product.returnNotice?.[0]?.value : ""}
                        </span>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            );
          })}
      </div>
    </>
  );
};
