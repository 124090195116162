import { useRouter } from "next/router";
import React from "react";
import { useAuthState } from "../contexts/AuthContext";
import { Loader } from "./Loader";

const ProtectedRoute: React.FunctionComponent = ({ children }) => {
  const appState = useAuthState();

  const router = useRouter();

  if (appState.authStatus === "authenticated") {
    return <>{children}</>;
  }
  if (appState.authStatus === "anonymous") {
    // go to login
    router.push("/login");
  }
  if (appState.authStatus === "validating") {
    return (
      <>
        <Loader isFullHeight role="status"></Loader>
      </>
    );
  } else {
    return <></>;
  }
};

export default ProtectedRoute;
