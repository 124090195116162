import React from "react";
import { classNames } from "../../utils/helper";
import { style } from "../styles/styles";

interface SectionHeadlineProps {
  title: string;
  additionalClassNames?: string;
}

export const SectionHeadline: React.FC<SectionHeadlineProps> = ({ title, additionalClassNames }) => {
  return (
    <h2 className={classNames(`mt-6 mb-6 ${style.headline1}`, additionalClassNames ? additionalClassNames : "")}>
      {title}
    </h2>
  );
};
