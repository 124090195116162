import { SupportCaseState } from "../frontend/state/SupportCaseState";
import { BillbeeOrderState } from "../model/Order";
import { OrderItem } from "../model/OrderItem";
import {
  SupportCaseBackendWip,
  SupportCaseCreatedWith,
  SupportCaseDb,
  SupportCaseEmail,
  SupportCaseFromApi,
} from "../model/SupportCase";
import { SupportCaseInput } from "../model/SupportCaseInput";

export class SupportCaseMapper {
  public static wipToFrontend(scWip: SupportCaseBackendWip): SupportCaseFromApi {
    return {
      ordernumber: scWip.ordernumber,
      orderState: scWip.orderState,
      invoiceDate: scWip.invoiceDate,
      complaintDate: scWip.complaintDate || new Date(),
      country: scWip.country,
      agreedTerms: scWip.agreedTerms,
      customerMessage: scWip.customerMessage,
      status: scWip.status,
      returnItems: scWip.returnItems
        .filter((item) => item.returnQuantity)
        .map((item) => ({
          returnQuantity: item.returnQuantity,
          sku: item.sku,
          title: item.title || "",
          batchNumber: item.batchNumber,
          complaintDescription: item.complaintDescription,
          complaintImages: item.complaintImages,
          isComplaint: item.isComplaint,
          reason: item.reason || "",
          solution: item.solution || "",
          returnConditions: item.returnConditions,
          returnFee: item.returnFee,
          returnInstructions: item.returnInstructions,
          returnNotice: item.returnNotice,
          returnParcelService: item.returnParcelService,
          totalPrice: 0,
        })),
    };
  }

  public static wipToEmail(scWip: SupportCaseBackendWip): SupportCaseEmail {
    return {
      ordernumber: scWip.ordernumber,
      orderState: scWip.orderState,
      invoiceDate: scWip.invoiceDate,
      complaintDate: scWip.complaintDate || new Date(),
      email: scWip.email,
      name: {
        first: scWip.name.first,
        last: scWip.name.last,
      },
      country: scWip.country,
      agreedTerms: scWip.agreedTerms,
      customerMessage: scWip.customerMessage,
      status: scWip.status,
      returnItems: scWip.returnItems
        .filter((item) => item.returnQuantity)
        .map((item) => ({
          returnQuantity: item.returnQuantity,
          sku: item.sku,
          title: item.title || "",
          batchNumber: item.batchNumber,
          complaintDescription: item.complaintDescription,
          complaintImages: item.complaintImages,
          isComplaint: item.isComplaint,
          reason: item.reason || "",
          solution: item.solution || "",
          returnConditions: item.returnConditions,
          returnFee: item.returnFee,
          returnInstructions: item.returnInstructions,
          returnNotice: item.returnNotice,
          returnParcelService: item.returnParcelService,
          totalPrice: item.totalPrice,
        })),
    };
  }

  public static stateToApi(scState: SupportCaseState): SupportCaseInput {
    return {
      ordernumber: scState.ordernumber,
      agreedTerms: scState.agreedTerms,
      // add this fixed value always when sending to api
      createdWith: SupportCaseCreatedWith.serviceportal,
      customerMessage: scState.customerMessage,
      // invoiceDate: scState.invoiceDate, // get this in backend
      status: scState.status,
      returnItems: scState.items
        // returnQuantity is needed
        .filter((item) => item.returnQuantity)
        .map((item) => ({
          returnQuantity: item.returnQuantity,
          sku: item.product.sku,
          title: item.product.title,
          batchNumber: item.batchNumber,
          complaintDescription: item.complaintDescription,
          complaintImages: item.complaintImages,
          isComplaint: item.isComplaint,
          reason: item.reason,
          solution: item.solution,
          totalPrice: 0, // get this in backend
        })),
    };
  }

  public static apiToState(
    scFromDb: SupportCaseDb,
    orderItems: OrderItem[],
    orderState: BillbeeOrderState,
  ): SupportCaseState {
    const scState: SupportCaseState = {
      status: scFromDb.status,
      country: scFromDb.country,
      ordernumber: scFromDb.ordernumber,
      orderState: orderState,
      agreedTerms: scFromDb.agreedTerms,
      invoiceDate: scFromDb.invoiceDate ? scFromDb.invoiceDate.toString().split("T")[0] : undefined,
      customerMessage: scFromDb.customerMessage,
      items: orderItems.map((orderItem) => {
        const returnItem = scFromDb.returnItems?.find((returnItem) => returnItem.sku === orderItem.product.sku);
        return {
          isComplaint: returnItem?.isComplaint,
          reason: returnItem?.reason,
          solution: returnItem?.solution,
          batchNumber: returnItem?.batchNumber,
          complaintImages: returnItem?.complaintImages ?? [],
          complaintDescription: returnItem?.complaintDescription,
          returnQuantity: returnItem?.returnQuantity ?? 0,
          returnParcelService: returnItem?.returnParcelService,
          returnFee: returnItem?.returnFee,
          purchaseQuantity: orderItem.quantity,
          product: orderItem.product,
        };
      }),
    };
    return scState;
  }
}
