import React from "react";
import { Reason } from "../../model/Reason";
import { SupportCaseState, SupportCaseStateAction } from "../state/SupportCaseState";
import { CurrentListing } from "./CurrentListing";

interface CurrentConfigProps {
  supportCase: SupportCaseState;
  dispatchSupportCaseAction: (supportCaseAction: SupportCaseStateAction) => void;
  reasonsWithSolutions: Reason[];
}

export const CurrentConfig: React.FC<CurrentConfigProps> = ({
  supportCase,
  dispatchSupportCaseAction,
  reasonsWithSolutions,
}) => {
  return (
    <div className="sticky mt-20 bg-white rounded-lg shadow-2xl top-5">
      <div className="rounded-t-lg ">
        <CurrentListing
          supportCase={supportCase}
          dispatchSupportCaseAction={dispatchSupportCaseAction}
          reasonsWithSolutions={reasonsWithSolutions}
        />
      </div>
    </div>
  );
};
