import React, { ChangeEvent } from "react";

interface ComplaintDescriptionProps {
  value: string;
  handleChange: (event: ChangeEvent<HTMLTextAreaElement>) => void;
}

export const ComplaintDescription: React.FC<ComplaintDescriptionProps> = ({ value, handleChange }) => {
  return (
    <div className="mt-1">
      <textarea
        id="complaintDescription"
        name="complaintDescription"
        rows={10}
        placeholder="Eine detaillierte Beschreibung hilft uns dein Problem besser zu verstehen und dir schnellstmöglich weiterzuhelfen."
        value={value}
        className="block w-full border border-gray-300 rounded-md shadow-sm focus:ring-primary-500 focus:border-primary-500"
        onChange={handleChange}
      />
    </div>
  );
};
