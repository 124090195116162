import { MenuIcon, XIcon } from "@heroicons/react/outline";
import React from "react";
import { Button } from "./Button";

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface MobileMenuButtonProps {
  isOpen: boolean;
  handleClick(e: React.MouseEvent<HTMLDivElement, MouseEvent>): void;
}

export const MobileMenuButton: React.FC<MobileMenuButtonProps> = ({ isOpen, handleClick }) => {
  return (
    <div
      onClick={(e) => handleClick(e)}
      className="fixed z-10 block top-4 right-4 md:hidden focus-visible:outline-none focus:outline-none"
      tabIndex={0}
    >
      <Button variant="primary" type="button" isCircle>
        {isOpen ? (
          <XIcon pointerEvents="none" className="w-5 h-5" />
        ) : (
          <MenuIcon pointerEvents="none" className="w-5 h-5" />
        )}
      </Button>
    </div>
  );
};
