import React from "react";
import { GenericImage } from "../../model/GenericImage";
import { ComplaintImage } from "./ComplaintImage";

interface ComplaintImagesProps {
  images: GenericImage[];
  handleDelete: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, id: string) => void;
  handleAreAllImagesFullyLoaded: () => void;
}

export const ComplaintImages: React.FC<ComplaintImagesProps> = ({
  images,
  handleDelete,
  handleAreAllImagesFullyLoaded,
}) => {
  return (
    <div className="mt-7">
      <>
        <div role="list" className="grid gap-4 grid-cols-imagegrid">
          {images.map((file) => (
            <ComplaintImage
              handleDelete={handleDelete}
              image={file}
              key={file.id}
              handleAreAllImagesFullyLoaded={handleAreAllImagesFullyLoaded}
            />
          ))}
        </div>
      </>
    </div>
  );
};
