import Image from "next/image";
import React, { useState } from "react";
import { GenericImage } from "../../model/GenericImage";

interface ComplaintImageProps {
  image: GenericImage;
  handleDelete: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, id: string) => void;
  handleAreAllImagesFullyLoaded: () => void;
}

export const ComplaintImage: React.FC<ComplaintImageProps> = ({
  image,
  handleDelete,
  handleAreAllImagesFullyLoaded,
}) => {
  const [imageLoadedComplete, setImageLoadedComplete] = useState(false);

  return (
    <>
      <div key={image.src} className="relative block">
        {imageLoadedComplete && (
          <button
            onClick={(e) => handleDelete(e, image.id)}
            className="absolute z-10 block cursor-pointer -right-3 -top-3"
          >
            <svg xmlns="http://www.w3.org/2000/svg" className="w-7 h-7" viewBox="0 0 24 24" stroke="white">
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                fill="black"
                strokeWidth={2}
                d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"
              />
            </svg>
          </button>
        )}
        <Image
          src={image.src}
          width={image.width ?? 300}
          height={image.height ?? 300}
          objectFit="cover"
          className="rounded-lg shadow-lg"
          onLoadingComplete={() => {
            image.isLoaded = true;
            setImageLoadedComplete(true);
            handleAreAllImagesFullyLoaded();
          }}
        />
      </div>
    </>
  );
};
