import React from "react";
import { style } from "../styles/styles";
import { Alert } from "./Alert";

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface SimpleNotificationProps {
  title: JSX.Element | string;
  description: JSX.Element | string;
}

export const SimpleNotification: React.FC<SimpleNotificationProps> = ({ title, description }) => {
  return (
    <>
      <>
        <div className={`mt-5 mb-10`}>
          <Alert variant="warning">
            <div className="flex flex-col">
              <p className={`${style.paragraph} ${style.headlineMeta} mb-2`}>{title}</p>
              <p className={`${style.paragraph} mb-2`}>
                <span>{description}</span>
              </p>
            </div>
          </Alert>
        </div>
      </>
    </>
  );
};
