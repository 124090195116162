import { Dialog } from "@headlessui/react";
import { ExclamationIcon } from "@heroicons/react/solid";
import React, { useRef } from "react";
import { SupportCaseState, SupportCaseStateAction } from "../state/SupportCaseState";
import { style } from "../styles/styles";
import { Button } from "./Button";
import { Modal } from "./Modal";

interface EmptySolutionDialogProps {
  isOpen: boolean;
  setOpen(is: boolean): void;
  supportCase: SupportCaseState;
  dispatchSupportCaseAction: (supportCaseAction: SupportCaseStateAction) => void;
}
export const EmptySolutionDialog: React.FC<EmptySolutionDialogProps> = ({
  isOpen,
  setOpen,
  supportCase,
  dispatchSupportCaseAction,
}) => {
  const cancelButtonRef = useRef(null);

  function handleOnClose(): void {
    setOpen(false);
  }

  return (
    <Modal cancelButtonRef={cancelButtonRef} handleOnClose={handleOnClose} isOpen={isOpen} setOpen={setOpen}>
      <div className="sm:flex sm:items-start">
        <div className="flex items-center justify-center flex-shrink-0 w-12 h-12 mx-auto bg-red-100 rounded-full sm:mx-0 sm:h-10 sm:w-10">
          <ExclamationIcon className={`w-6 h-6 ${style.colorPrimary}`} aria-hidden="true" />
        </div>
        <div className="mt-5 sm:mt-0 sm:ml-6">
          <Dialog.Title as="h3" className={`${style.headline4}`}>
            Bitte Lösung auswählen
          </Dialog.Title>
          <div className="mt-2">
            <p className={`${style.paragraph}`}>
              Du hast Produkte mit in die Anfrage aufgenommen, für die noch keine gewünschte Lösung gewählt wurde. Bitte
              wähle einen Lösungsweg aus oder entferne den Artikel aus der Serviceanfrage.
            </p>
          </div>
        </div>
      </div>
      <div className="flex justify-end mt-8">
        <div className="mr-4">
          <Button
            type="button"
            variant="primary"
            onClick={() => {
              supportCase.items.forEach((item) => {
                if (!item.solution) {
                  dispatchSupportCaseAction({
                    type: "update-item",
                    item,
                    itemAction: { type: "reset-item" },
                  });
                }
                setOpen(false);
              });
            }}
          >
            {supportCase.items.filter((item) => item.returnQuantity > 0 && !item.solution).length || ""} Artikel
            entfernen
          </Button>
        </div>
        <Button type="button" variant="secondary" onClick={() => setOpen(false)} ref={cancelButtonRef}>
          Zurück
        </Button>
      </div>
    </Modal>
  );
};
