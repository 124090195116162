import marked from "marked";
import { SupportCaseStateItem } from "../frontend/state/SupportCaseItemState";
import { FormbenchProduct } from "../model/FormbenchProduct";
import { ReturnItemDb } from "../model/ReturnItem";

export function mailButtonTemplate(buttonText: string, buttonLink: string): string {
  return `<p style="margin-bottom: 2rem; margin-top: 1rem;">
            <table class="row" width="600" border="0" cellpadding="0" cellspacing="0" align="center" style="width:600px;max-width:600px;">
              <tr>
                <td align="center" valign="middle">
                  <table class="centerFloat" border="0" align="center" cellpadding="0" cellspacing="0">
                    <tr>
                      <td class="contBtn" align="center" style="display: block; background-color: #e2718f; border-color: #e2718f; border-width: 2px; border-style: solid; border-radius: 50px;"><a href="${buttonLink}" target="_blank" style="font-family: Montserrat, DejaVu Sans, Verdana, sans‑serif; font-size: 16px; line-height: 26px; font-weight: 700; letter-spacing: 0.5px; text-align: center; color: #fff; display: inline-block; text-decoration: none; padding: 10px 30px;">${buttonText}</a></td>
                    </tr>
                  </table>
                </td>
              </tr>
            </table>
          </p>
  `;
}

function parseCustomMarkdownHtml(item: string): string {
  const mdToHtml = item
    .split("||")
    .map((it) => {
      return marked(it, {});
    })
    .join("");
  // ? why does this not work with replaceAll
  const replaced = mdToHtml.replace(/\|/g, "<br />");
  return replaced;
}

export function getParcelServiceTextMail(item: ReturnItemDb, lang = "de_DE", product?: FormbenchProduct): string {
  if (!item.returnParcelService) return "";

  const text =
    item.returnParcelService.returnsLabelText.find((l) => l.lang === lang)?.value ||
    item.returnParcelService.returnsLabelText[0].value;

  // Don't show the link if the returnFee is different from the return fee of the parentProduct
  const link =
    item.returnFee || !product?.parentProduct || item.returnFee === product.parentProduct.returnFee
      ? mailButtonTemplate(`Rücksendelabel drucken`, item.returnParcelService?.returnsLabelLink)
      : "";

  const parcelServiceText = text.replace("{{returnsLabelLink}}", link);
  const md = parseCustomMarkdownHtml(parcelServiceText);
  return md;
}

export function getParcelServiceText(item: SupportCaseStateItem, lang = "de_DE"): string[] {
  if (!item.returnParcelService) return [""];

  const text =
    item.returnParcelService.returnsLabelText.find((l) => l.lang === lang)?.value ||
    item.returnParcelService.returnsLabelText[0].value;

  const parcelServiceText = text.split("{{returnsLabelLink}}");

  const parcelServiceTextWithHtml = parcelServiceText.map((item) => {
    return parseCustomMarkdownHtml(item);
  });

  return parcelServiceTextWithHtml;
}
