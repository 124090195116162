import React, { useState } from "react";
import { BillbeeOrderState } from "../../model/Order";
import { Reason } from "../../model/Reason";
import { isOrderCancelable, isOrderTrackable } from "../../utils/helper";
import { SupportCaseState, SupportCaseStateAction } from "../state/SupportCaseState";
import { CurrentConfig } from "./CurrentConfig";
import { SupportCaseEditor } from "./Editor/SupportCaseEditor";
import { MobileMenuButton } from "./MobileMenuButton";
import { SimpleNotification } from "./SimpleNotification";
import { SlideOver } from "./SlideOver";

interface SupportCaseEditorLayoutProps {
  supportCase: SupportCaseState;
  dispatchSupportCaseAction: React.Dispatch<SupportCaseStateAction>;
  reasonsWithSolutions: Reason[];
  isOpenMobileMenu: boolean;
  setIsOpenMobileMenu: React.Dispatch<React.SetStateAction<boolean>>;
}

export const SupportCaseEditorLayout: React.FC<SupportCaseEditorLayoutProps> = ({
  supportCase,
  dispatchSupportCaseAction,
  reasonsWithSolutions,
  isOpenMobileMenu,
  setIsOpenMobileMenu,
}) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [isCancelingOrder, setIsCancelingOrder] = useState(false);

  return (
    <>
      <>
        <div className="grid grid-cols-12 md:gap-8">
          <div className="col-span-full md:col-span-6 lg:col-span-8">
            {
              // safe to cast, order state is always available here
              isOrderCancelable(supportCase.orderState as BillbeeOrderState) && (
                <SimpleNotification
                  title="Bestellung stornieren?"
                  description={
                    <>
                      Wenn du eine Stornierung vornehmen willst, sende bitte eine kurze E-Mail an{" "}
                      <a
                        href={`mailto:kundenservice@ehrenkind.de?subject=Bestellung stornieren, Bestellnummer ${supportCase.ordernumber}`}
                      >
                        kundenservice@ehrenkind.de
                      </a>{" "}
                      unter Angabe der Bestellnummer. Wenn du eine Serviceanfrage zu einem Produkt einreichen möchtest,
                      wähle bitte weiter unten bei den Bestellpositionen dein Produkt aus und fülle das Formular aus.
                    </>
                  }
                />
              )
            }
            {
              // safe to cast, order state is always available here
              isOrderTrackable(supportCase.orderState as BillbeeOrderState) && (
                <SimpleNotification
                  title="Frage zum Versand?"
                  description={
                    <>
                      Wenn du eine Frage zum Versand deiner Bestellung hast, schreibe uns bitte kurz eine E-Mail an{" "}
                      <a
                        href={`mailto:kundenservice@ehrenkind.de?subject=Frage zum Versand, Bestellnummer ${supportCase.ordernumber}`}
                      >
                        kundenservice@ehrenkind.de
                      </a>{" "}
                      unter Angabe der Bestellnummer. Wenn du eine Serviceanfrage zu einem Produkt einreichen möchtest,
                      wähle bitte weiter unten bei den Bestellpositionen dein Produkt aus und fülle das Formular aus.
                    </>
                  }
                />
              )
            }
            {!isCancelingOrder && (
              <SupportCaseEditor
                supportCase={supportCase}
                dispatchSupportCaseAction={dispatchSupportCaseAction}
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                reasonsWithSolutions={reasonsWithSolutions!}
              />
            )}
          </div>
          <div className="hidden md:block md:col-span-6 lg:col-span-4">
            <CurrentConfig
              supportCase={supportCase}
              dispatchSupportCaseAction={dispatchSupportCaseAction}
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              reasonsWithSolutions={reasonsWithSolutions!}
            />
          </div>
        </div>

        {!isOpenMobileMenu && (
          <MobileMenuButton
            isOpen={isOpenMobileMenu}
            handleClick={(e) => {
              e.preventDefault();
              setIsOpenMobileMenu((s) => !s);
            }}
          />
        )}
        <SlideOver
          supportCase={supportCase}
          dispatchSupportCaseAction={dispatchSupportCaseAction}
          reasonsWithSolutions={reasonsWithSolutions}
          isOpen={isOpenMobileMenu}
          handleOnClose={(bool) => setIsOpenMobileMenu(bool)}
          handleCloseClick={(e) => {
            e.preventDefault();
            setIsOpenMobileMenu(false);
          }}
        />
      </>
    </>
  );
};
